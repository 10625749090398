<!-- 閱讀測驗試題範例 -->
<template>
  <div class="TopicReadTest">
    <h1 class="TopicReadTest-h1">
      試題範例
    </h1>
    <div class="TopicReadTest-div">
      <img :src="dataForm.image_uri" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopicReadTest',
  props: {
    dataForm: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style lang="less" scoped>
.TopicReadTest {
  width: 100%;
  .TopicReadTest-h1 {
    padding-bottom: 10px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    color: #0C4FA2;
  }
  .TopicReadTest-div {
    width: 100%;
    height: calc(100% - 45px);
    overflow-x: auto;
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>