<!-- 閱讀測驗題目及答案 -->
<template>
  <div class="TopicReadAnswer">
    <!-- 題型title -->
    <h1 class="TopicReadAnswer-h1">
      {{ dataForm.group_title }}. 閱讀（{{ partTitle }}）
      <!-- {{ topicData.progressNum || topicList[0].progressNum }}. 閱讀（{{ partTitle }}） -->
    </h1>
    <div class="TopicReadAnswer-box">
      <!-- 題目-STA -->
      <!-- 题目类型 文 -->
      <div class="box-left topic1" ref="topicRef1" v-if="topicType == 1">
        <div v-html="topicData.content"></div>
      </div>
      <!-- 题目类型 图 -->
      <div class="box-left topic1" ref="topicRef2" v-if="topicType == 2">
        <img :src="topicData.image_uri" alt="">
      </div>
      <!-- 题目类型 文+图 -->
      <div class="box-left topic1" ref="topicRef3" v-if="topicType == 4">
        <div v-html="topicData.content"></div>
        <img :src="topicData.image_uri" alt="">
      </div>
      <!-- 題目-END -->
      

      <!-- 答案-STA -->
      <div class="box-right-div">
        <!-- 答案类型 单体选项 -->
        <div class="box-right answer1" ref="answerRef1" v-if="dataForm.group_type == 1 && answerType == 1">
          <div
            :class="topicData.answer.answer == item.option_title ? 'right-box active' : 'right-box'"
            v-for="(item, index) in topicData.option"
            :key="index"
            @click="typeCheck(item.option_title)"
          >
            <span>{{item.option_title}}</span>
            <p v-if="item.content && item.show_type != 2" v-html="item.content"></p>
            <img v-if="item.show_type == 2" :src="item.image_uri" alt="">
          </div>
        </div>

        <!-- 答案类型 题组选项 -->
        <div class="box-right answer2" ref="answerRef2" v-else>
          <div v-for="(item, index) in topicList" :key="index">
            <div class="right-box" v-if="item.show_type != 7">
              <div class="right-box-left">
                {{item.progressNum}}.
              </div>
              <div class="right-box-right">
                <h3 class="right-box-h3">
                  <span v-if="item.show_type == 1 || item.show_type == 4" v-html="item.content">
                  </span>
                </h3>
                <img class="right-box-img" v-if="item.show_type == 2 || item.show_type == 4" :src="item.image_uri" alt="">
                <div
                  :class="topicList[index].answer.answer == items.option_title ? 'right-box-div active' : 'right-box-div'"
                  v-for="(items, indexs) in item.option"
                  :key="indexs"
                  @click="typeCheck2(items.option_title, index)"
                >
                  <span>{{items.option_title}}</span>
                  <p v-if="items.content && items.show_type != 2" v-html="items.content"></p>
                  <img v-if="items.show_type == 2" :src="items.image_uri" alt="">
                </div>
              </div>
            </div>
            <div class="right-boxs" v-else>
              <h3 class="right-box-h3" v-html="item.content">
              </h3>
              <div
                :class="topicList[index].answer.answer == items.option_title ? 'right-box-div active' : 'right-box-div'"
                v-for="(items, indexs) in item.option"
                :key="indexs"
                @click="typeCheck2(items.option_title, index)"
              >
                <span>{{items.option_title}}</span>
                <p v-if="items.content && items.show_type != 2" v-html="items.content"></p>
                <img v-if="items.show_type == 2" :src="items.image_uri" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 遮罩層 -->
        <!-- <div class="right-mask"></div> -->
      </div>
      <!-- 答案-END -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopicReadAnswer',
  props: {
    partTitle: {
      type: String,
      default: ''
    },
    dataForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 题目数据
      topicData: {},
      // 题组数据
      topicList: [],
      // 题目类型
      topicType: 0,
      // 答案类型
      answerType: 0,
    }
  },
  watch: {
    dataForm: {
      handler() {
        // 初始化的時候根據判斷渲染數據
        if (this.dataForm.group_type == 1) { // 1單體形式  2題組形式
          const data = this.dataForm.question?.[0]
          // 數據賦值
          this.topicData = data
          // 題目類型賦值
          this.topicType = data.show_type
          // 答案類型賦值
          this.answerType = data.answer_type
          // 答案賦值
          this.answerCheck = data.answer.answer
        } else {
          // 數據賦值
          this.topicList = this.dataForm.question
          // 數據賦值
          this.topicData = this.dataForm
          // 題目類型賦值
          this.topicType = this.dataForm.show_type
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 答案選中
    typeCheck(id) {
      this.topicData.answer.answer = id
      this.$emit('playChange', {type: 1, data: this.topicData})
    },
    // 答案選中
    typeCheck2(id, index) {
      this.topicList[index].answer.answer = id
      this.$emit('playChange', {type: 2, data: this.topicList})
    },
    // 頁面滾動到頂部
    toRoll() {
      // 初始化的时候答案區塊滚动条置顶
      if (this.$refs.answerRef1) this.$refs.answerRef1.scrollTop = 0
      if (this.$refs.answerRef2) this.$refs.answerRef2.scrollTop = 0
      // 初始化的时候問題區塊滚动条置顶
      if (this.$refs.topicRef1) this.$refs.topicRef1.scrollTop = 0
      if (this.$refs.topicRef2) this.$refs.topicRef2.scrollTop = 0
      if (this.$refs.topicRef3) this.$refs.topicRef3.scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
.TopicReadAnswer {
  width: 100%;
  height: 100%;
  .TopicReadAnswer-h1 {
    padding-bottom: 20px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #333333;
  }
  .TopicReadAnswer-box {
    width: 100%;
    height: calc(100% - 55px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-left {
      width: calc(50% - 10px);
      height: 100%;
      overflow: auto;
    }
    .box-right {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .box-right-div {
      padding-bottom: 20px;
      width: calc(50% - 10px);
      height: calc(100% - 20px);
      position: relative;
      .right-mask {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: 0px 0px 0px 4px;
        pointer-events: none;
      }
    }
    .topic1 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: #333333;
      white-space: pre-line;
      img {
        width: 100%;
        height: auto;
      }
    }
    .answer1 {
      .right-box {
        cursor: pointer;
        user-select: none;
        padding: 10px 0;
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background: #EEEEEE;
          border-radius: 50%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 48px;
          text-align: center;
          color: #333333;
        }
        p {
          width: calc(100% - 58px);
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #000000;
        }
        img {
          max-width: calc(100% - 58px);
        }
      }
      .active {
        span {
          background: #0C4FA2;
          color: #FFFFFF;
        }
        p {
          color: #0C4FA2;
        }
      }
    }
    .answer2 {
      .right-box {
        display: flex;
        justify-content: space-between;
        .right-box-left {
          width: 50px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #333333;
        }
        .right-box-right {
          width: calc(100% - 50px);
          .right-box-h3 {
            margin-bottom: 8px;
            min-height: 40px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            color: #333333;
            white-space: pre-wrap;
          }
          .right-box-img {
            margin: 8px 0;
            width: 100%;
            height: auto;
          }
          .right-box-div {
            cursor: pointer;
            user-select: none;
            padding: 12px 0;
            display: flex;
            span {
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              background: #EEEEEE;
              border-radius: 50%;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 700;
              font-size: 28px;
              line-height: 48px;
              color: #333333;
            }
            p {
              width: calc(100% - 58px);
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 700;
              font-size: 30px;
              line-height: 48px;
              color: #000000;
            }
            img {
              max-width: calc(100% - 58px);
            }
          }
          .active {
            span {
              background: #0C4FA2;
              color: #FFFFFF;
            }
            p {
              color: #0C4FA2;
            }
          }
        }
      }
      .right-boxs {
        padding-left: 20px;
        width: calc(100% - 20px);
        .right-box-h3 {
          margin-bottom: 28px;
          min-height: 40px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #333333;
        }
        .right-box-div {
          cursor: pointer;
          user-select: none;
          padding: 12px 0;
          display: flex;
          span {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            background: #EEEEEE;
            border-radius: 50%;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 48px;
            color: #333333;
          }
          p {
            width: calc(100% - 58px);
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 48px;
            color: #000000;
          }
          img {
            max-width: calc(100% - 58px);
          }
        }
        .active {
          span {
            background: #0C4FA2;
            color: #FFFFFF;
          }
          p {
            color: #0C4FA2;
          }
        }
      }
    }
  }
}
</style>